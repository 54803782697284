<section id="footer" class="px-2 px-lg-5 px-md-4">
    <div class="row h-100 justify-content-center m-0">
        <div class="col-xl-6 py-2 align-self-center d-flex justify-content-center justify-content-xl-start">
            <div class="row">
                <div class="col-xl-8 col-lg-12 d-flex justify-content-start">
                    <div class="row ">
                        <img class="pr-2" src="../../assets/img/webp/foto-gis-pin-map.webp"
                            alt="{{ 'seo.fotoGisPinMap' | translate }}">
                        <a href="https://www.ngn-itsolutions.eu/" class="d-flex align-items-center"
                            target="_blank">Powered by NGN IT-Solutions</a>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12 d-flex justify-content-center">
                    <img class="pl-4" src="../../assets/img/webp/germany-flag.webp"
                        alt="{{ 'seo.germanyFlag' | translate }}">
                    <img class="pl-4" src="../../assets/img/webp/icon-ssl-secure.webp"
                        alt="{{ 'seo.iconSslSecure' | translate }}">
                    <img class="pl-4" src="../../assets/img/logo-VATM.svg" alt="VATM company logo">
                    <img class="pl-4" src="../../assets/img/webp/breko_logo_white.webp" alt="BREKO company logo">
                </div>
            </div>
        </div>
        <div class="col-xl-6 py-2 align-self-center d-flex justify-content-center justify-content-xl-end">
            <a class="pr-4" routerLink="/agb">{{ 'footer.agb' | translate }}</a>
            <a class="pr-4" routerLink="/privacy">{{ 'footer.privacy' | translate }}</a>
            <a class="pr-4" routerLink="/impressum">{{ 'footer.impressum' | translate }}</a>
        </div>
    </div>
</section>